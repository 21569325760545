.react-colorful {
    position:relative;
    display:flex;
    flex-direction:column;
    width:200px;
    height:200px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
}

.react-colorful__saturation {
    position:relative;
    flex-grow:1;
    border-bottom:12px solid #000;
    border-radius:4px 4px 0 0;
    background-image:linear-gradient(0deg,#000,transparent),linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
}

.react-colorful__alpha-gradient,.react-colorful__pointer-fill {
    content:"";
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    pointer-events:none;
    border-radius:inherit;
}

.react-colorful__alpha-gradient,.react-colorful__saturation {
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.05);
}

.react-colorful__alpha,.react-colorful__hue {
    position:relative;
    height:24px;
}

.react-colorful__hue {
    background:linear-gradient(90deg,red 0,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,red);
}

.react-colorful__last-control {
    border-radius:0 0 4px 4px;
}

.react-colorful__interactive {
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    border-radius:inherit;
    outline:none;
    touch-action:none;
}

.react-colorful__pointer {
    position:absolute;
    z-index:1;
    box-sizing:border-box;
    width:28px;
    height:28px;
    transform:translate(-50%,-50%);
    background-color:#fff;
    border:2px solid #fff;
    border-radius:50%;
    box-shadow:0 2px 4px rgba(0,0,0,.2);
}

.react-colorful__interactive:focus .react-colorful__pointer {
    transform:translate(-50%,-50%) scale(1.1);
}

.react-colorful__alpha,.react-colorful__alpha-pointer {
    background-color:#fff;
    background-image:url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M80h8v8H8zM08h8v8H0z"/></svg>');
}

.react-colorful__saturation-pointer {
    z-index:3;
}

.react-colorful__hue-pointer {
    z-index:2;
}