.text-area {
  display: block;
  overflow: hidden;
  resize: none;
  width: 100%;
  tab-index: 1;
  top: 0;
  left: 0;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px #3182CE;
    //background-color: #EDF2F7;
    color: #171923;
  }
}